<template>
  <div
    class="user-fluid-layout__header"
    :class="{ showMobileSearchBar: mobileShowSearchBar }"
  >
    <b-container fluid v-if="showResponsiveHeader">
      <div class="ufl-header__div">
        <div class="ufl-header__left">
          <div
            id="userFluidLayoutMenuToggle"
            class="user-fluid-layout__menu-toggle"
            :class="{ rotate: expandSideNav }"
            @click="$emit('toggleSideNav')"
          >
            <img
              :src="
                require(`@/assets/images/layout/user-fluid-layout/menu-toggle.svg`)
              "
              alt="toggle side nav"
            />
          </div>
          <div class="user-fluid-layout__logo" v-if="!mobileShowSearchBar">
            <img
              :src="
                require(`@/assets/images/logo/whoa-logo-black-${logoLanguage}.svg`)
              "
              alt="WHO Academy"
              height="48"
              class="c-pointer"
              @click="$emit('logoClicked')"
            />
          </div>
          <environment-label />
        </div>
        <SearchBar
          v-if="!mobileShowSearchBar"
          @onFocus="$emit('searchBarFocused')"
          @closeSearch="showSearchBar = false"
          class="d-lg-block user-fluid-layout__search"
          :class="[showSearchBar ? 'd-block' : 'd-none']"
        />
        <div class="user-fluid-layout__settings" v-if="!mobileShowSearchBar">
          <div
            class="mr-2 search-bar-icon"
            :class="[showSearchBar ? 'd-none' : 'd-lg-none d-md-block']"
          >
            <img
              src="@/assets/images/icons/search-grey.svg"
              alt="search"
              @click="dispaySearchBar"
            />
          </div>
          <div
            id="mobileSearchBarIcon"
            class="mr-2 mobile-search-bar-icon"
            :class="[
              mobileShowSearchBar ? 'd-none' : 'd-lg-none d-md-none d-sm-block'
            ]"
          >
            <img
              src="@/assets/images/icons/search-grey.svg"
              alt="search"
              @click="mobileShowSearchBar = !mobileShowSearchBar"
            />
          </div>
          <div
            id="userFluidLayoutHelpIcon"
            class="user-fluid-layout__help-icon d-lg-block"
          >
            <img
              v-if="helpSectionHighlighted"
              src="@/assets/images/layout/user-fluid-layout/help-highlight.svg"
              alt="help"
              @click="$emit('isStartVueTour', true)"
            />
            <img
              v-else
              src="@/assets/images/layout/user-fluid-layout/help.svg"
              alt="help"
              class="user-fluid-layout__help-icon--help-icon"
              @click="$emit('isStartVueTour', true)"
            />
          </div>
          <div
            class="user-fluid-layout__profile-dropdown d-none d-sm-block d-md-block d-lg-block"
          >
            <b-dropdown variant="link" :right="language !== 'ar'" no-caret>
              <template #button-content>
                <b-img
                  :src="profileImageURL"
                  v-bind="{
                    width: 30,
                    height: 30,
                    class: profileImgClass
                  }"
                  rounded="circle"
                  alt="avatar"
                ></b-img>
                <img
                  src="@/assets/images/layout/alerts/icons/arrow-down.svg"
                  alt="arrow-down"
                  class="ufl__dropdown--caret"
                />
              </template>
              <b-dropdown-item
                href="javascript:;"
                @click="$router.push({ name: 'settings' })"
              >
                {{ $t("general.menu.settings") }}
              </b-dropdown-item>
              <b-dropdown-item href="javascript:;" @click="$emit('onLogout')">
                {{ $t("my_profile.log_out.title") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <LxpLanguageSelect
            :selected="selectedLanguage"
            :allLanguage="getAllLanguage()"
            :language="language"
            :parentClass="parentClass()"
            @set-language="setSelectedLanguage"
          />
        </div>
      </div>
      <div class="user-fluid-layout__search-title" v-if="mobileShowSearchBar">
        <h3>
          <img
            src="@/assets/images/icons/arrow-back.svg"
            @click="mobileShowSearchBar = !mobileShowSearchBar"
          />
          {{ $t("general.search") }}
        </h3>
        <SearchBar
          @onFocus="$emit('searchBarFocused')"
          class="user-fluid-layout__mobile-search"
        />
      </div>
    </b-container>
    <b-container fluid v-else>
      <div class="d-flex justify-content-between">
        <div
          class="user-fluid-layout__back-button align-self-center"
          @click="goBack"
        >
          {{ $t("general.back") }}
        </div>
        <div>
          <b-dropdown
            v-if="this.getSelectedCourse.is_enrolled && !this.isCourseStarted"
            right
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <img
                src="@/assets/images/vle/threedots.svg"
                width="18"
                height="20"
              />
            </template>
            <b-dropdown-item class="unenroll" v-b-modal.unenroll-modal>
              <div class="ac-mc__list">
                <img
                  src="@/assets/images/icons/unenroll-module.svg"
                  alt="unenroll"
                  class="mr-1"
                />
                {{ $t("course.unenroll.title") }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
// mixins
import getLogo from "@/core/mixins/getLogo";
// components
import EnvironmentLabel from "../../Shared/EnvironmentLabel.vue";
import SearchBar from "./Header/SearchBar.vue";
import { LxpLanguageSelect } from "didactica";
import userLanguageMixin from "@/core/mixins/userLanguage";

export default {
  mixins: [getLogo, userLanguageMixin],
  props: {
    expandSideNav: {
      type: Boolean,
      required: true
    },
    profileImageURL: {
      type: String,
      required: true
    },
    helpSectionHighlighted: {
      type: Boolean,
      required: true
    }
  },
  components: { SearchBar, EnvironmentLabel, LxpLanguageSelect },
  data() {
    return {
      showSearchBar: false,
      mobileShowSearchBar: false,
      screenWidth: 480,
      showResponsiveHeader: true,
      selectedLanguage: {}
    };
  },
  computed: {
    ...mapGetters(["getAuthProfile", "getSelectedCourse"]),
    isCourseStarted() {
      return this.getSelectedCourse.sections.some(a => a.resumeBlock);
    },
    isCourseEnded() {
      return moment(this.getSelectedCourse.current_server_time).isAfter(
        moment(this.getSelectedCourse.course_ends_at)
      );
    },
    profileImgClass() {
      let className = "user-fluid-layout__profile-img";
      if (this.getAuthProfile.profile_image_url) {
        className += " b-1";
      }
      return className;
    }
  },
  watch: {
    screenWidth() {
      this.showRouterView();
    },
    "$route.name"() {
      this.showHeader();
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.screenWidth = window.innerWidth;
  },
  methods: {
    onResize() {
      this.showHeader();
    },
    showHeader() {
      this.showResponsiveHeader = !(
        window.innerWidth < 480 &&
        this.$route.name.toUpperCase() === "COURSES DETAIL"
      );
    },
    goBack() {
      this.$router.push({ name: "Dashboard" });
    },
    dispaySearchBar() {
      this.showSearchBar = true;
    },
    myEventHandler(e) {
      this.screenWidth = e.target.innerWidth;
    },
    showRouterView() {
      if (this.screenWidth <= 480) {
        this.showSearchBar = false;
      } else {
        this.showSearchBar = false;
        this.mobileShowSearchBar = false;
      }
    }
  }
};
</script>

<style lang="scss">
.user-fluid-layout__header {
  background: $brand-primary-50;
  height: $ufl-header-height;
  padding: 16px 20px;

  .container-fluid {
    padding: 0;
    .ufl-header__div {
      @include horizontal-space-between;
      .ufl-header__left {
        @include horizontal-space-between;
        .user-fluid-layout__logo {
          padding-left: 24px;
        }
        .user-fluid-layout__menu-toggle {
          cursor: pointer;
          border-radius: 100px;
          height: 48px;
          width: 48px;
          background: #fff;
          filter: drop-shadow(0px 2px 10px rgba(9, 44, 78, 0.03))
            drop-shadow(0px 9px 30px rgba(9, 44, 78, 0.05));

          @include center;

          img {
            transition: transform 0.1s;
          }

          &.rotate {
            img {
              transform: rotate(-90deg);
            }
          }
        }
      }
      .user-fluid-layout__header-search-bar {
        flex-grow: 1;
        margin: 0 32px;
      }
      .user-fluid-layout__settings {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0;
        .user-fluid-layout__profile-dropdown {
          margin-left: 8px;
          button {
            padding: 0;

            &:focus {
              box-shadow: none;
            }
            .ufl__dropdown--caret {
              margin-left: 6px;
            }
            .user-fluid-layout__profile-img {
              &.b-1 {
                border: 1px solid $brand-neutral-200;
                object-fit: cover;
              }
            }
          }
          .dropdown-toggle {
            text-decoration: none;
            &::after {
              border-top: 0.3em solid $brand-primary-lighter;
              vertical-align: inherit;
            }
          }
          .dropdown:hover .dropdown-menu {
            display: block;
            margin-top: 0; // remove the gap so it doesn't close
          }

          .dropdown-menu {
            padding: 8px;
            background: #ffffff;
            box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.1);
            border-radius: 4px;
            border: 0;
          }
          .dropdown-item {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: $brand-public-secondary;
            padding: 8px;
            letter-spacing: 0.1px;

            &:hover,
            &:focus {
              background: $ufl-primary-bg;
            }
          }
        }
        .user-fluid-layout__help-icon {
          height: 42px;
          width: 42px;
          padding: 5px;
          border-radius: 100px;
          background: $brand-primary-50;
          .user-fluid-layout__help-icon--help-icon {
            width: 25px;
            height: 32px;
          }
          img {
            cursor: pointer;
          }
        }
        .lxp-select {
          .user-fluid-layout__language {
            .ufl-language__div {
              position: absolute;
              .ufl-language__item {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .search-bar-icon img {
    filter: invert(19%) sepia(83%) saturate(15%) hue-rotate(11deg)
      brightness(94%) contrast(81%);
  }
  .user-fluid-layout__search-title {
    display: none;
  }
}

@media screen and (max-width: 990px) {
  .user-fluid-layout__search-title {
    display: none !important;
    h3 {
      @include subtitle-large;
      color: $brand-neutral-900;
    }
  }
  .user-fluid-layout__search {
    .user-fluid-layout__header-search-bar {
      width: 32% !important;
    }
  }
  .mobile-search-bar-icon {
    display: none !important;
  }
}
@media screen and (max-width: 480px) {
  .user-fluid-layout__header {
    height: $ufl-header-height;
    &.showMobileSearchBar {
      height: calc($ufl-header-height + 45px);
    }
  }
  .user-fluid-layout__search {
    .user-fluid-layout__header-search-bar {
      display: none !important;
    }
  }
  .search-bar-icon {
    display: none !important;
  }

  .mobile-search-bar-icon {
    padding: 6px 9px;
    border-radius: 100px;
    display: block !important;
  }

  .user-fluid-layout__help-icon {
    display: none !important;
  }
  .user-fluid-layout__menu-toggle {
    display: none !important;
  }
  .user-fluid-layout__logo {
    padding-left: 0px !important;
  }
  .user-fluid-layout__search-title {
    display: block !important;
    h3 {
      text-align: left;
      padding-bottom: 12px;
      margin-bottom: 0px;
      img {
        width: 18px;
      }
    }
  }
  .user-fluid-layout__back-button {
    @include button-label;
    line-height: 19px;
    color: $brand-primary-lighter;
  }
}

[dir="rtl"] {
  .user-fluid-layout__header {
    .ufl-header__div {
      .user-fluid-layout__settings {
        justify-content: flex-end;
        left: auto;
        .user-fluid-layout__profile-dropdown {
          .ufl__dropdown--caret {
            margin-right: 6px;
          }
          .dropdown-toggle {
            &::after {
              margin: 0 0.255em 0 0;
            }
          }
          .dropdown-item {
            text-align: right;
          }
        }
        .user-fluid-layout__help-icon {
          margin-left: 8px;
          margin-right: 0px;
        }
      }

      .ufl-header__left {
        .user-fluid-layout__logo {
          padding: 0 24px 0 0;
        }
      }
    }
  }
}
</style>
